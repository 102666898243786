import Api from '@/axios'

export default {
  search (phone) {
    return Api().get(`old-systems/search?phone=${phone}`)
  },
  getActivities (id, page) {
    return Api().get(`old-systems/activities/${id}&page=${page}`)
  },
  getPackages (id, page) {
    return Api().get(`old-systems/packages/${id}&page=${page}`)
  },
  getPayments (id, page) {
    return Api().get(`old-systems/payments/${id}&page=${page}`)
  }
}
