<template>
  <b-container fluid>
    <b-row>
      <!-- Start Filter-->
      <b-container fluid class="filter">
        <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
          <form @submit.prevent="handleSubmit(onSubmit)">
            <b-row>
              <b-col lg="12">
                <iq-card>
                  <template v-slot:body>
                    <div class="new-user-info">
                      <b-row class="align-items-center">
                        <b-form-group class="col-md-10 mb-0" :label-for="'search'">
                          <ValidationProvider name="search" ref="search" rules="required" v-slot="{ errors }">
                            <b-form-input v-model="search" type="text" class="mb-0" placeholder="Enter Phone Number"
                                          :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                            <div class="invalid-feedback">
                              <span>{{ errors[0] }}</span>
                            </div>
                          </ValidationProvider>
                        </b-form-group>
                        <div class="col-md-2">
                          <b-button v-if="!loading" variant="primary" class="w-100" type="submit" >Search</b-button>
                          <b-button v-else variant="primary" class="w-100" disabled>
                            <b-spinner small type="grow"></b-spinner>
                            loading...
                          </b-button>
                        </div>
                      </b-row>
                    </div>
                  </template>
                </iq-card>
              </b-col>
            </b-row>
          </form>
        </ValidationObserver>
      </b-container>
      <!-- End Filter-->
      <b-container  v-if="loading" class="mt-2">
        <b-card class="d-flex justify-content-center">
          <spinner-loading text="Loading"></spinner-loading>
        </b-card>
      </b-container>
      <b-container v-if="userName && !loading">
        <b-card>
          <template v-slot:header>
            <h4 class="text-primary">{{userName}}</h4>
          </template>
        <tab-nav :pills="true" class="nav-fill mb-3 profile-feed-items" id="pills-tab-1">
          <tab-nav-items :active="true" id="pills-home-tab-fill" href="#pills-home-fill"
                         ariaControls="pills-home-fill" role="tab" :ariaSelected="true" title="Packages" />
          <tab-nav-items @click="getActivities" :active="false" id="pills-profile-tab-fill" href="#pills-profile-fill"
                         ariaControls="pills-profile-fill" role="tab" :ariaSelected="false" title="Activities" />
          <tab-nav-items @click="getPayments" :active="false" id="pills-contact-tab-fill" href="#pills-contact-fill"
                         ariaControls="pills-contact-fill" role="tab" :ariaSelected="false" title="Payments" />
        </tab-nav>
        <tab-content id="pills-tabContent-1" class="mt-0">
          <tab-content-item :active="true" id="pills-home-fill" aria-labelled-by="pills-home-tab-fill">
            <b-table striped responsive :fields="headerPackage" :items="allPackage" class="mb-0 table-borderless"
                     primary-key="name" id="my-table">
            </b-table>
            <b-pagination v-model="paginationPackages.currentPage"
                          class="mt-3"
                          :total-rows="paginationPackages.total"
                          align="right"
                          :per-page="paginationPackages.per_page"
                          aria-controls="my-table"
                          @input="getAllPackages()"
            ></b-pagination>
          </tab-content-item>
          <tab-content-item :active="false" id="pills-profile-fill" aria-labelled-by="pills-profile-tab-fill">
            <b-table striped responsive :fields="headerActivities" :items="allActivities" class="mb-0 table-borderless"
                     primary-key="name" id="my-table">
            </b-table>
            <b-pagination v-model="paginationActivities.currentPage"
                          class="mt-3"
                          :total-rows="paginationActivities.total"
                          align="right"
                          :per-page="paginationActivities.per_page"
                          aria-controls="my-table"
                          @input="getActivities()"
            ></b-pagination>
          </tab-content-item>
          <tab-content-item :active="false" id="pills-contact-fill" aria-labelled-by="pills-contact-tab-fill">
            <b-table striped responsive :fields="headerPayments" :items="allPayments" class="mb-0 table-borderless"
                     primary-key="name" id="my-table">
            </b-table>
            <b-pagination v-model="paginationPayments.currentPage"
                          class="mt-3"
                          :total-rows="paginationPayments.total"
                          align="right"
                          :per-page="paginationPayments.per_page"
                          aria-controls="my-table"
                          @input="getPayments()"
            ></b-pagination>
          </tab-content-item>
        </tab-content>
        </b-card>
      </b-container>
      <b-container v-if="notFound" class="mt-2">
        <b-card>
          <p class="text-center mb-0"> Not Found </p>
        </b-card>
      </b-container>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../config/pluginInit'
import oldServices from '../services/services'
export default {
  name: 'oldSystem',
  data () {
    return {
      search: '',
      userName: '',
      userId: '',
      allPackage: [],
      headerPackage: [
        { label: 'Package Name', key: 'name', class: 'text-left' },
        { label: 'Package Count', key: 'package_count', class: 'text-left' },
        { label: 'Invitation Balance', key: 'invitation_balance', class: 'text-left' },
        { label: 'Freezing Balance', key: 'freezing_balance', class: 'text-left' },
        { label: 'Freezing', key: 'freezing', class: 'text-left' },
        { label: 'Status', key: 'status', class: 'text-left' },
        { label: 'Start Date', key: 'start_date', class: 'text-left' },
        { label: 'End Date', key: 'end_date', class: 'text-left' },
        { label: 'Note', key: 'notes', class: 'text-left' }
      ],
      paginationPackages: {
        currentPage: 1,
        per_page: 10,
        total: 6
      },
      allActivities: [],
      headerActivities: [
        { label: 'Package Name', key: 'name', class: 'text-left' },
        { label: 'check_in', key: 'check_in', class: 'text-left' },
        { label: 'check_out', key: 'check_out', class: 'text-left' }
      ],
      paginationActivities: {
        currentPage: 1,
        per_page: 10,
        total: 6
      },
      allPayments: [],
      headerPayments: [
        { label: 'Package Name', key: 'name', class: 'text-left' },
        { label: 'notes', key: 'notes', class: 'text-left' },
        { label: 'date', key: 'date', class: 'text-left' },
        { label: 'Amount', key: 'amount', class: 'text-left' },
        { label: 'Offer', key: 'offer', class: 'text-left' },
        { label: 'Package Cost', key: 'package_cost', class: 'text-left' },
        { label: 'Remaining Value', key: 'remaining_value', class: 'text-left' }
      ],
      paginationPayments: {
        currentPage: 1,
        per_page: 10,
        total: 6
      },
      loading: false,
      notFound: false
    }
  },
  computed: {
  },
  methods: {
    onSubmit () {
      this.loading = true
      this.notFound = false
      oldServices.search(this.search).then(res => {
        this.userName = res.data.data.length > 0 ? res.data.data[0].name : ''
        if (res.data.data.length > 0 && res.data.data[0].id) {
          this.userId = res.data.data[0].id
          oldServices.getPackages(res.data.data[0].id, this.paginationPackages.currentPage).then(response => {
            this.allPackage = response.data.data.data
          })
          this.loading = false
        } else {
          this.loading = false
          this.notFound = true
        }
      })
    },
    getAllPackages () {
      oldServices.getActivities(this.userId, this.paginationPackages.currentPage).then(response => {
        this.allActivities = response.data.data.data
        this.paginationPackages.currentPage = response.data.data.meta.current_page
        this.paginationPackages.per_page = response.data.data.meta.per_page
        this.paginationPackages.total = response.data.data.meta.total
      })
    },
    getActivities () {
      oldServices.getActivities(this.userId, this.paginationActivities.currentPage).then(response => {
        this.allActivities = response.data.data.data
        this.paginationActivities.currentPage = response.data.data.meta.current_page
        this.paginationActivities.per_page = response.data.data.meta.per_page
        this.paginationActivities.total = response.data.data.meta.total
      })
    },
    getPayments () {
      oldServices.getPayments(this.userId, this.paginationPayments.currentPage).then(response => {
        this.allPayments = response.data.data.data
        this.paginationPayments.currentPage = response.data.data.meta.current_page
        this.paginationPayments.per_page = response.data.data.meta.per_page
        this.paginationPayments.total = response.data.data.meta.total
      })
    }
  },
  components: {
  },
  mounted () {
    core.index()
  },
  created () {
  }
}
</script>
